import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import LinkBubble from "../components/linkBubble"

const BlogIndex = ({ data, location }) => {

  /*
  let siteTitle = "";
  try{ siteTitle = data.site.siteMetadata.title}catch {siteTitle = "quetty"}
  
  const posts = data.allMarkdownRemark.edges*/

  return (
    <StaticQuery query={graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {frontmatter: {status: {eq: "release"}}}
      ) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
            }
          }
        }
      }
    }
  `
  } 
    render={data=> (
      <Layout location={location} title={data.site.siteMetadata.title}>
      <SEO title="All posts" />

      <LinkBubble title="All games/apps" to="/games"></LinkBubble>
      <LinkBubble title="All articles" to="/blog"></LinkBubble>  

      
      <Bio />
      {data.allMarkdownRemark.edges.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug}>
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
    )} />
  )
}

export default BlogIndex

